// material
import { Box, Grid, Container, Typography } from "@mui/material";
// components
import Page from "../components/Page";
import {
  AppTasks,
  AppNewUsers,
  AppBugReports,
  AppItemOrders,
  AppNewsUpdate,
  AppWeeklySales,
  AppOrderTimeline,
  AppCurrentVisits,
  AppWebsiteVisits,
  AppTrafficBySite,
  AppCurrentSubject,
  AppConversionRates,
} from "../components/_dashboard/app";
import DashboardCircle from "src/components/_dashboard/app/DashboardCircle";
import { ReactVideoPlayer } from "src/components";
import EventCarousel from "src/components/_dashboard/app/EventCarousel";
import ReviewCardSlider from "src/components/_dashboard/app/ReviewCards";
import ShowEvenetSlider from "src/components/_dashboard/app/EventSlider";
import { useContext, useState } from "react";
import { useContentSetting } from "src/Hooks/ContentContext/ContentSettingState";
import { project_name } from "src/config/config";
import DashboardCard from "src/components/_dashboard/app/DashboardCard";
import { _get_is_seen_video_localStorage } from "src/DAL/localStorage/localStorage";
// ----------------------------------------------------------------------

export default function DashboardApp() {
  const {
    contentSettingData,
    dashboardSettingData,
    dashboardTestimonialData,
    dashboardEventData,
  } = useContentSetting();
  const is_seen_video = _get_is_seen_video_localStorage();

  console.log(dashboardSettingData, "ok");
  return (
    // <Page title="Dashboard ">
    <div className="container">
      <div className="row">
        <div className="col-12 dashboard_description">
          {contentSettingData && contentSettingData.dashboard_description ? (
            <div
              dangerouslySetInnerHTML={{
                __html: contentSettingData.dashboard_description,
              }}></div>
          ) : (
            <h2 className="dashboard-heading">
              {dashboardSettingData?.wellcome_text}
            </h2>
          )}
        </div>
      </div>
      <div className="row">
        {/* <div className="col-12 mt-5 mx-auto text-center">
          <DashboardCard data={dashboardSettingData} />
        </div> */}
        {(project_name == "DD" ||
          project_name == "BASIC_DEV" ||
          project_name == "PGI_DEV") && (
          <>
            <div className="col-sm-12 col-md-12 col-lg-12 ">
              <DashboardCircle data={dashboardSettingData} />
            </div>
          </>
        )}
        {dashboardSettingData?.video_url ? (
          <span>
            {is_seen_video === "No" && (
              <div className="col-sm-12 col-md-12 col-lg-12 my-5">
                <ReactVideoPlayer
                  url={dashboardSettingData?.video_url}
                  setting={dashboardSettingData}
                />
              </div>
            )}
          </span>
        ) : (
          <div
            dangerouslySetInnerHTML={{
              __html: dashboardSettingData?.welcome_image,
            }}></div>
        )}
        <div className="col-sm-12 col-md-12 col-lg-12 mt-5 text-center">
          {/* <EventCarousel /> */}
          <ShowEvenetSlider
            headingData={dashboardSettingData?.upcoming_events_heading}
            data={dashboardEventData}
          />
        </div>
        <div className="col-sm-12 col-md-12 col-lg-12 mt-5 text-center">
          <ReviewCardSlider
            headingData={dashboardSettingData?.client_reviews_heading}
            data={dashboardTestimonialData}
          />
        </div>
      </div>
    </div>
    // </Page>
  );
}
